<template>
  <Modal
    :ui="{ root: { color: 'bg-#F7F9F2', rounded: 'rounded-t-4.75 lg:rounded-l-10 lg:rounded-r-0', padding: 'px-3 pt-4 pb-6 lg:p-10', size: 'max-w-248' }, wrapper: { addition: 'max-lg:pt-10vh' }, close:{addition:'lg:hidden'} }"
    position="right"
    @close="emits('closeModal')"
  >
    <Reviews
      :restaurant-id="currentFullRestaurantReviewsState.restaurantId"
      :can-create-feedback="currentFullRestaurantReviewsState.canCreateFeedback"
      title="Отзывы о заведении"
      review-form-wrapper-class="max-lg:w-[calc(100%+1.5rem)] max-lg:-ml-3"
    />
  </Modal>
</template>

<script setup lang="ts">
import { useRestaurantReviewsPopup } from '~/composables/popups/useRestaurantReviewsPopup';

const emits = defineEmits(['closeModal']);

const { currentFullRestaurantReviewsState } = useRestaurantReviewsPopup();

</script>
